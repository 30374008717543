/* Team.css */

.team-page {
    padding: 2rem;
  }
  
  .base-header h3 {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .teamContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
 
  
  .team-member {
    /* height: 50%; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
  }
  @media (max-width: 991px){
    .team-member{
      display: block;
    }
  }
  .team-img-wrapper,
  .team-member-info {
    /* width: 50%; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .team-img-wrapper {
    /* background-color: black; */
    border-radius: 50%;
  }
  
  .team-img-wrapper img {
    /* height: 25rem;
    width: 25rem; */
    border-radius: 50%;
    object-fit: cover;
  }
  
  .team-member-info {
    padding: 1.8rem;
  }
  
  .team-member-info ul {
    list-style-type: circle;
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
    align-items: center;
    justify-content: center;
  }
  
  .team-member-info li {
    font-size: 1.2rem;
    color: black;
  }
  
  .social.list-inline {
    display: flex;
    list-style-type: none;
    gap: 1rem;
  }
  
  .social.list-inline li {
    margin: 0;
  }
  